import { KindeProvider } from '@kinde-oss/kinde-auth-react';
import React from 'react';
import { createRoot } from 'react-dom/client';

import MainContainer from './components/MainContainer';
import { setOrgCode } from './Globals.js';
import reportWebVitals from './reportWebVitals';
import { retrieveFromServer } from './services/AwCrudService.js';

retrieveFromServer('user/auth-config').then((config) => {
	setOrgCode(config.orgCode);
	const root = createRoot(document.getElementById('root'));
	root.render(
		<React.StrictMode>
			<KindeProvider {...config}>
				<MainContainer />
			</KindeProvider>
		</React.StrictMode>
	);
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
//comment
